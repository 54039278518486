<template>
  <transition name="fade" v-if="show">
    <section class="screen-modal info-modal" :class="modalClass">
      <div class="modal-backdrop"></div>
      <div class="modal-content">
        <header v-if="icon" class="modal-header">
          <svg-icon :icon="icon"></svg-icon>
        </header>
        <section class="modal-inner-content">
          <slot></slot>
        </section>
        <footer class="modal-footer">
          <button v-if="showCancelButton" class="modal-btn" @click="closeModal">
            {{ cancelButtonLabel }}
          </button>
          <button class="modal-btn" @click="confirm">
            {{ confirmButtonLabel }}
          </button>
        </footer>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  name: "InfoModal",
  methods: {
    closeModal() {
      if (this.cancelAction) {
        this.cancelAction();
      } else {
        this.$emit("close");
      }
    },
    confirm() {
      if (this.confirmAction) {
        this.confirmAction();
      } else {
        this.$emit("close");
      }
    }
  },
  props: {
    confirmAction: {
      required: false,
      type: Function,
      default: null
    },
    show: {
      required: false,
      type: Boolean
    },
    confirmButtonLabel: {
      required: false,
      type: String,
      default: "OK"
    },
    cancelButtonLabel: {
      required: false,
      type: String,
      default: "Cancel"
    },
    icon: {
      required: false,
      type: String,
      default: ""
    },
    showCancelButton: {
      required: false,
      type: Boolean
    },
    cancelAction: {
      required: false,
      type: Function,
      default: null
    },
    modalClass: {
      required: false,
      type: String,
      default: ""
    }
  }
};
</script>
